<template>
  <div class="MyTransactions">
    <Spacer num="1" />
    <Title :label="$locale['my_transactions']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MyTransactionsCard"
          v-for="(subscription, index) in items"
          :key="index"
          :label="`<span third-bold>@${subscription.creator.user}</span>`"
          :desc="`<div><div date>${$locale['activated']} ${date_locale(subscription.activated)}</div>${getStatus(subscription)}</div>`"
          :UserAvatar="subscription.creator"
          :iconSize="`48px`"
          :rightDesc="`${subscription.isActive ? `<go-emoji name='heart'></go-emoji>` : `<go-emoji name='heart_broken'></go-emoji>`}`"
          @click="
            view({
              title: $locale['subscription'],
              component: () => import('@/components/user/Subscription.vue'),
              class: `menu bottom`,
              closeOutside: true,
              animation: 'bottomIn',
              subscription,
            })
          "
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.totalItems" :message="$locale['no_subscriptions_message']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "./UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/user/my-subscriptions`,
      storeId: "my_transactions",
      items: [],
      statistics: { totalItems: 0, page: 1 },
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    expireDate: function(subscription) {
      var date = new Date(subscription.activated);

      const periods = {
        monthly: 30,
        biannual: 365 / 2,
        annually: 365,
      };

      const ndays = periods[subscription.period] || periods["monthly"];

      date.setDate(date.getDate() + ndays);
      return date.toLocaleDateString();
    },
    getStatus: function(subscription) {
      return subscription.isActive ? "" : ` <div warn tag>${this.$locale["subscription_expired"]}</div>`;
    },
  },
};
</script>

<style lang="scss">
.MyTransactions {
  .MyTransactionsCard .LargeButtonDesc {
    opacity: 1;
  }

  .MyTransactionsCard [date] {
    opacity: 0.5;
  }

  .MyTransactionsCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }
}
</style>
